import React, { Fragment, useEffect, useState } from 'react'
import {Container, Row, Col, Button, Modal } from 'react-bootstrap'
import axios from 'axios'

function Content() {
    const [career, listCareer] = useState([]);
    const per = useState(10);
    const page = useState(1);
    const [show, setShow] = useState(false);
    const [detail, setDetail] = useState([]);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        const url = process.env.REACT_APP_API + `careers/` + e
        axios.get(url)
        .then(res => {
            setDetail(res.data)
        }) 
        setShow(true)
    }
    useEffect(() => {
        const url = process.env.REACT_APP_API + `careers?per=${per}&page=${page}`
        axios.get(url)
        .then(res => {
            listCareer(res.data)
        })
        .catch(error => console.log(error))
    },[]);
    if (career.length === 0) {
        return(
            <section className='list-career'>
                <Container>
                    <div className='section-heading mb-4 mc-xl-5 mb-lg-5'>
                        <h2 className='title-heading'>Careers</h2>
                    </div>
                    <Row className='align-items-center'>
                        <Col xl='6' lg='6' md='6'>
                            <div className='section-head'>
                                <p className='title'>We`re Hiring</p>
                                <p className='desc'>We are always open to accept new members who have the same vision and mission as us.</p>
                            </div>
                        </Col>
                        <Col xl='12' lg='12' md='12'>
                            <div className='section-body'>
                                <Row>
                                    <p>Careers Not Available</p>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
    return (
        <Fragment>
            <section className='list-career'>
                <Container>
                    <div className='section-heading mb-4 mc-xl-5 mb-lg-5'>
                        <h2 className='title-heading'>Careers</h2>
                    </div>
                    <Row className='align-items-center'>
                        <Col xl='6' lg='6' md='6'>
                            <div className='section-head'>
                                <p className='title'>We`re Hiring</p>
                                <p className='desc'>We are always open to accept new members who have the same vision and mission as us.</p>
                            </div>
                        </Col>
                        <Col xl='12' lg='12' md='12'>
                            <div className='section-body'>
                                <Row>
                                    {
                                        career.data.sources.map((career, i) => {
                                            return(
                                                <Col md='6' lg='4' key={i}>
                                                    <div className='card shadow-lg lift h-100'>
                                                        <div className='card-body p-5 d-flex flex-row'>
                                                            <div>
                                                                <span className='avatar bg-section text-white me-4'>{career.title.match(/\b(\w)/g).join('').toUpperCase()}</span>
                                                            </div>
                                                            <div>
                                                                <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">{career.employment_status === '1' ? 'Full Time' : 'Part Time'}</span>
                                                                <h4 className="mb-1">{career.title}</h4>
                                                                <Button className='btn btn-sm btn-more mt-3' onClick={() => handleShow(career.slug)}>Discover More</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <strong>{detail.title}</strong>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <div dangerouslySetInnerHTML={{__html: detail.deskripsi}}></div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}
export default Content;