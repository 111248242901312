import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel'
import AOS from 'aos';

function Content() {
  useEffect(() => {
    AOS.init({
      // duration : 5000
    });
    AOS.refresh();
  }, []);
  const options = {
      loop: true,
      dots: false,
      nav: false,
      autoplay: true,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      items: 1,
      navText : ['<i class="fas fa-angle-left"></i>','<i class="fas fa-angle-right"></i>'],
      navElement: 'div'
  }
  return (
    <Fragment>
      <section className='content-about h-100'>
        <Container>
          <div className='section-head text-center mb-3 mb-xl-5 mb-lg-4 mb-md-3'>
            <h3>INTRODUCTION</h3>
          </div>
          <div className='section-body pb-3 pb-xl-5 pb-lg-5 pb-md-4'>
            <Row className='align-items-center justify-content-between '>
              <Col xl='5' lg='5' md='5'>
                <div className='box-image'>
                  <img src='/assets/images/logo/logoAIM.png' alt='Logo AIM HIGH' className='img-fluid' />
                </div>
              </Col>
              <Col xl='6' lg='6' md='6'>
                <h4 className='pb-3'>Artha Inti Mentari</h4>
                <p className='desc-about pb-3'>PT. Artha Inti Mentari (AIM) established in November 2021, is an FMCG company that operates in Indonesia. AIM is part of a company that has a big name in various industries such as Property and Development, Manufacturing, Distribution, Automotive, Retail, etc. AIM enterprise holding company is already a public listed company in Indonesia & Hongkong and operated in China, Vietnam, Cambodia, etc.</p>
                <p className='desc-about pb-3'>AIM Focus to serve the customer with various brands like Le Nord, Earthganic, ThinkinBae, Verites, and Luvmier. These products are best products from AIM focusing on Body Care, Perfume, and Skincare.</p>
                <p className='desc-about'>AIM will continue to innovate in creating superior products that will become favorites and trendsetters in Indonesia.</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xl='6' lg='6' md='6'>
                <Card className='h-100'>
                    <Card.Header>Vision</Card.Header>
                    <Card.Body>Become a leader in Beauty, Personal Care & Baby Product in ASEAN region</Card.Body>
                </Card>
            </Col>
            <Col xl='6' lg='6' md='6' className='pt-3 pt-xl-0 pt-lg-0 pt-md-3'>
                <Card className='h-100'>
                    <Card.Header>Mission</Card.Header>
                    <Card.Body>Provide High Quality Product that suitable with Customer.</Card.Body>
                </Card>
            </Col>
        </Row>
      </Container>
      </section>
      <section className='h-100 py-0 our-company'>
        <div className='section-head text-center mb-3 mb-xl-5 mb-lg-4 mb-md-3'>
          <h3>Our Company</h3>
        </div>
      </section>
      <section data-aos="fade-left" data-aos-delay="200" className='section-list bg-section'>
        <Container>
          <Row className='align-items-center justify-content-between'>
            <Col xl='6' lg='6' md='6' className='order-2'>
              <div className='box-image'>
                <video className="mx-auto"  id="featured-video-mobile" autoPlay loop muted>
                  <source src="/assets/videos/about/imv.mp4" type="video/mp4"/>
                  "Your browser does not support the video tag."
                  </video>
              </div>
            </Col>
            <Col xl='5' lg='5' md='5' className='order-1'>
              <h4 className='pb-3'>IMV</h4>
              <p className='desc-about'>IMV have been established since 1996 in Vietnam. We are a distribution company which covering up general trade, modern trade as well the e-commerce channel. IMV have 4 offices in Vietnam (Ho Chi Minh City, Ha Noi, Da Nang and Can Tho) and network all over 63 provinces in Vietnam. With dynamic and creative team of sales and marketing, we build a strong brand image and awareness for our long term partners Fujifilm and Pigeon in Vietnam.</p>
              <a href='/' className='btn mt-4 btn-more-1'>Discover More</a>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-right" data-aos-delay="300" className='section-list'>
        <Container>
          <Row className='align-items-center justify-content-between'>
            <Col xl='6' lg='6' md='6' className='order-1 border-bg'>
              <div className='box-image'>
                <OwlCarousel className="owl-theme" {...options}>
                  <div className="item">
                    <img src='/assets/images/about/modernland-1.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-2.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-3.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-4.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-5.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-6.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-7.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-8.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-9.jpg' alt='' className='img-fluid'/>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
            <Col xl='5' lg='5' md='5' className='order-2'>
              <h4 className='pb-3'>Modernland</h4>
              <p className='desc-about'>PT Modernland Realty Tbk was established more than 30 years ago as the pioneer of property business in Indonesia. Over the decades, the Company has proven its dedication and participation in developing the best properties in the country, particularly in Greater Jakarta, in accordance with the increasing green awareness and quality standards. Today, the Company’s core businesses are real estate development, golf course and country club operations, general contracting, and trading.</p>
              <a href='/' className='btn mt-4 btn-more'>Discover More</a>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-left" data-aos-delay="400" className='section-list bg-section'>
        <Container>
          <Row className='align-items-center justify-content-between'>
            <Col xl='5' lg='5' md='5' className='order-2 border-bg'>
              <div className='box-image'>
                <OwlCarousel className="owl-theme" {...options}>
                  <div className="item">
                    <img src='/assets/images/about/modernland-internasional-1.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-internasional-2.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-internasional-3.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-internasional-4.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-internasional-5.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-internasional-6.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-internasional-7.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/modernland-internasional-8.jpg' alt='' className='img-fluid'/>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
            <Col xl='5' lg='5' md='5' className='order-1'>
              <h4 className='pb-3'>Modern Internasional</h4>
              <p className='desc-about'>PT Modern Internasional Tbk. was founded on May 12, 1971 under the name of PT Modern Photo Film Company. Having undergone several changes in the Articles of Association of the Company, by the Notarial Act Budiarti Karnadi, SH No. 48, dated May 26, 1997, regarding changes to the Articles of Association of the Company, including change of the Company name, became PT Modern Photo Tbk and in June 2007, with the global business changes that happened, PT Modern Photo Tbk. changed its name to PT Modern Internasional Tbk. and since 1971, the Company has become the sole distributor for all Fujifilm Japan products in Indonesia.</p>
              <a href='/' className='btn mt-4 btn-more-1'>Discover More</a>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-right" data-aos-delay="500" className='section-list'>
        <Container>
          <Row className='align-items-center justify-content-between'>
            <Col xl='6' lg='6' md='6' className='order-1 border-bg'>
              <div className='box-image'>
                <OwlCarousel className="owl-theme" {...options}>
                  <div className="item">
                    <img src='/assets/images/about/multiindocintra-1.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/multiindocintra-2.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/multiindocintra-3.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/multiindocintra-4.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/multiindocintra-5.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/multiindocintra-6.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/multiindocintra-7.jpg' alt='' className='img-fluid'/>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
            <Col xl='5' lg='5' md='5' className='order-2'>
              <h4 className='pb-3'>Multi IndoCitra</h4>
              <p className='desc-about'>Multi Indo Citra, distributes baby and cosmetic health products. Business activities started in 1990 and are increasingly growing and expanding with several business lines such as accessories products for mother and baby and various other skincare products.</p>
              <a href='/' className='btn mt-4 btn-more'>Discover More</a>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-left" data-aos-delay="600" className='section-list bg-section'>
        <Container>
          <Row className='align-items-center justify-content-between'>
            <Col xl='6' lg='6' md='6' className='order-2 border-bg'>
              <div className='box-image'>
                <OwlCarousel className="owl-theme" {...options}>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-1.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-2.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-3.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-4.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-5.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-6.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-7.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-8.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-9.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-10.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-11.jpg' alt='' className='img-fluid'/>
                  </div>
                  <div className="item">
                    <img src='/assets/images/about/yesstar-12.jpg' alt='' className='img-fluid'/>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
            <Col xl='5' lg='5' md='5' className='order-1'>
              <h4 className='pb-3'>Yes!Star</h4>
              <p className='desc-about'>Yestar Healthcare Holdings Company Limited is one of the leading high margin medical consumables and equipment companies in the PRC. Targeting the booming domestic healthcare industry, Yestar's core business focuses on high margin healthcare consumables and equipment namely, medical imaging products and In Vitro Diagnostic (“IVD”) products. Since its inception, the Group has established an extensive sales network in the PRC backed by a solid and systematic management system.</p>
              <a href='/' className='btn mt-4 btn-more-1'>Discover More</a>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-right" data-aos-delay="700" className='section-list'>
        <Container>
          <Row className='align-items-center justify-content-between'>
            <Col xl='6' lg='6' md='6' className='order-1 border-bg'>
              <div className='box-image'>
                <OwlCarousel className="owl-theme" {...options}>
                    <div className="item">
                      <img src='/assets/images/about/mg-1.jpg' alt='' className='img-fluid'/>
                    </div>
                    <div className="item">
                      <img src='/assets/images/about/mg-2.jpg' alt='' className='img-fluid'/>
                    </div>
                    <div className="item">
                      <img src='/assets/images/about/mg-3.jpg' alt='' className='img-fluid'/>
                    </div>
                    <div className="item">
                      <img src='/assets/images/about/mg-4.jpg' alt='' className='img-fluid'/>
                    </div>
                    <div className="item">
                      <img src='/assets/images/about/mg-5.jpg' alt='' className='img-fluid'/>
                    </div>
                    <div className="item">
                      <img src='/assets/images/about/mg-6.jpg' alt='' className='img-fluid'/>
                    </div>
                    <div className="item">
                      <img src='/assets/images/about/mg-7.jpg' alt='' className='img-fluid'/>
                    </div>
                    <div className="item">
                      <img src='/assets/images/about/mg-8.jpg' alt='' className='img-fluid'/>
                    </div>
                    <div className="item">
                      <img src='/assets/images/about/mg-9.jpg' alt='' className='img-fluid'/>
                    </div>
                    <div className="item">
                      <img src='/assets/images/about/mg-10.jpg' alt='' className='img-fluid'/>
                    </div>
                  </OwlCarousel>
              </div>
            </Col>
            <Col xl='5' lg='5' md='5' className='order-2'>
              <h4 className='pb-3'>MG Shanghai International Co. Ltd</h4>
              <p className='desc-about'>Shanghai Mijihua International Trading Co., Ltd. is a subsidiary of Capital Group, which is mainly engaged in the national fast-moving consumer goods business (MG for short). Since 2010, it has gradually represented Japan Mandon Company, Japan Pigeon Company, and American Lansinoh Company. We serve FMCG Products, from toiletries, wipes, diaper to mother & baby food, and currently one of the few distributors capable of covering all channels across the country. In 2013, it successfully passed the ISO9001:2008 quality management system certification. The products represented by MG in traditional channels, modern channels, E-commerce, etc.</p>
              <a href='/' className='btn mt-4 btn-more'>Discover More</a>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}
export default Content;