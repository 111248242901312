import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
// content
import Hero from '../content/home/Hero'
import About from '../content/home/About'
import Brand from '../content/home/Brand'
import Career from '../content/home/Career'

function Home() {
  return (
    <Fragment>
        <Helmet>
          <link rel="canonical" href={process.env.REACT_APP_STAGING} />
          <link rel='shortlink' href={process.env.REACT_APP_STAGING} />
          {/* Primary Meta Tags */}
          <title>Artha Inti Mentari</title>
          <meta name="title" content="Arta Inti Mentari"/>
          <meta name="description" content="Arta Inti Mentari"/>
          <meta name="keywords" content="Arta Inti Mentari"/>

          <meta itemprop="title" content="Arta Inti Mentari"/>
          <meta itemprop="description" content="Arta Inti Mentari"/>
          <meta itemprop="image" content="/fav-icon-gst.png"/>

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={process.env.REACT_APP_STAGING}/>
          <meta property="og:title" content="Arta Inti Mentari"/>
          <meta property="og:description" content="Arta Inti Mentari"/>
          <meta property="og:image" content="/fav-icon-gst.png"/>

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content={process.env.REACT_APP_STAGING}/>
          <meta property="twitter:title" content="Arta Inti Mentari"/>
          <meta property="twitter:description" content="Arta Inti Mentari"/>
          <meta property="twitter:image" content="/fav-icon-gst.png"/>
          
          <meta name="robots" content="index, follow"/>
          <meta name="googlebot" content="index, follow"/>
          <meta name="googlebot-news" content="index, follow"/>
      </Helmet>
      <Hero/>
      <About/>
      <Brand/>
      <Career/>
    </Fragment>
  )
}
export default Home;