import React, { Fragment } from 'react'
import { Container, Col } from 'react-bootstrap'

function About() {
    return (
        <Fragment>
            <section className='about py-5 py-xl-0 py-lg-0 py-md-0 bg-circle d-flex align-items-center'>
                <Container fluid className='px-0'>
                    <div className='d-md-flex full-wrap'>
                        <div className="half order-md-last d-flex align-items-stretch">
                            <div id='img' className="img w-100" style={{ backgroundImage: 'url(/assets/images/banner/banner.png)' }}></div>
                        </div>
                        <div className="half d-flex">
                            <div className="text-about pr-md-5">
                                <Col md='5' className="d-flex px-3 px-xl-0 px-lg-0 px-md-3">
                                    <div className="heading-section">
                                        <div className='section-head'>
                                            <h2>About Us</h2>
                                        </div>
                                        <div className='section-body py-3 py-xl-5 py-lg-5 py-md-4'>
                                            <p className='pb-3 pb-xl-5 pb-lg-5 pb-md-4'>PT. Artha Inti Mentari (AIM) established in November 2021, is an FMCG company that operates in Indonesia. AIM is part of a company that has a big name in various industries such as Property and Development, Manufacturing, Distribution, Automotive, Retail, etc. AIM enterprise holding company is already a public listed company in Indonesia & Hongkong and operated in China, Vietnam, Cambodia, etc.</p>
                                            <a href='/about-us' className='btn btn-more'>Discover More</a>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </Fragment>
    );
}

export default About;