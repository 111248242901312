import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import ScrollTop from './ScrollTop'
// common
import Header from './components/common/Header'
import Footer from './components/common/Footer'
// page
import Home from './components/pages/Home'
import Brands from './components/pages/Brands'
import AboutUs from './components/pages/AboutUs'
import Careers from './components/pages/Careers'
import ContactUs from './components/pages/ContactUs'
import NotFound from './components/pages/NotFound'

function App() {
  return (
    <Fragment>
      <ScrollTop>
        <Header/>
        <div className='wrapper'>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/brands' component={Brands} />
            <Route exact path='/about-us' component={AboutUs} />
            <Route exact path='/career' component={Careers} />
            <Route exact path='/contact-us' component={ContactUs} />
            <Route component={NotFound}/>
          </Switch>
        </div>
        <Footer/>
      </ScrollTop>
    </Fragment>
  );
}

export default App;
