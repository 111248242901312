import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'

function Content() {
    const [brands, listbrands] = useState([]);
    useEffect(() => {
        const url = process.env.REACT_APP_API + `brands`
        axios.get(url)
        .then(res => {
            listbrands(res.data.data)
        })
        .catch(error => console.log(error))
    },[]);
    return (
        <Fragment>
            <section className='list-brand h-100'>
                <Container>
                    <div className='section-heading'>
                        <p>AIM menyediakan produk keseharian yang berkualitas. AIM menyediakan berbagai produk kebutuhan sehari-hari seperti sabun, shampo, facial wash, skin care, kebutuhan bayi, dan lain-lain.</p>
                    </div>
                    <div className='section-body mt-5'>
                        <Row>
                            {
                                brands.map((data, i) => {
                                    return(
                                        <Col xl='3' lg='3' md='6' className='p-3' key={i}>
                                            <div className='box'>
                                                <div className='box-logo'>
                                                    <img src={data.logo} className='img-fluid' alt={data.title} width='150' height='150' />
                                                </div>
                                                <div className='box-desc'>
                                                    <a href={data.link} className='btn btn-visit'>Discover More</a>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </div>
                </Container>
            </section>
        </Fragment>
    );
}

export default Content;