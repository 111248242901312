import React, { Fragment, useEffect } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import $ from 'jquery'
import { useLocation } from 'react-router-dom'

function Header() {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const isSticky = (e) => {
		const header = document.querySelector("header");
		const scrollTop = window.scrollY;
		scrollTop >= 1
			? header.classList.add("sticky")
			: header.classList.remove("sticky");
	};

	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
    });
    const toggleClass = () => {
        const icon_bar = document.querySelector('#icon_bars');
        icon_bar.classList.toggle('fa-times');
    }

    $(window).ready(function() {
        const heightHeader = $('#header').height();
        $('div#basic-navbar-nav').css({"top": heightHeader+"px"})
    });
    return (
        <Fragment>
            <header id='header'>
                <Navbar expand='lg'>
                    <Container>
                        <Navbar.Brand href='/' title='Logo'><img src='/assets/images/logo/AIM.png' alt='logo aim' className='img-fluid' width='245' height='80' /></Navbar.Brand>
                        <Navbar.Toggle onClick={toggleClass}><i className="fas fa-bars" id="icon_bars"></i></Navbar.Toggle>
                        <Navbar.Collapse className='align-items-center justify-content-end'  id='basic-navbar-nav'>
                            <Nav as='ul' id='navigation'>
                                <Nav.Item as='li' className='ms-xl-0 ms-lg-0 ms-md-0'>
                                    <Nav.Link href="/brands" className={splitLocation[1] === "brands" ? "active" : ""} title='Brands'>Brands</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as='li'>
                                    <Nav.Link href="/about-us" className={splitLocation[1] === "about-us" ? "active" : ""} title='About Us'>About Us</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as='li'>
                                    <Nav.Link href="/career" className={splitLocation[1] === "career" ? "active" : ""} title='Careers'>Careers</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as='li' className='me-xl-0 me-lg-0 me-md-0'>
                                    <Nav.Link href="/contact-us" className={splitLocation[1] === "contact-us" ? "active" : ""} title='Contact Us'>Contact Us</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </Fragment>
    )
}

export default Header;