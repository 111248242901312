import React, { Fragment } from 'react'
import OwlCarousel from 'react-owl-carousel'
import $ from 'jquery'

function Hero() {
    const options = {
        loop: true,
        dots: true,
        nav: false,
        autoplay: true,
        smartSpeed: 1250,
        items: 1
    }

    $(document).ready(function(){
        const heightnavbar = $('#header').height();
        const slideHeight = document.getElementById('hero-home');
        slideHeight.style.marginTop = heightnavbar  + 'px';
        slideHeight.style.height = 'calc(100vh - '+ heightnavbar +'px)'
    })

    return (
        <Fragment>
            <section className='hero' id='hero-home'>
                <OwlCarousel className="owl-theme" {...options}>
                    <div className='item' >
                        <div className='item-slide' style={{width: '100%', height: '100%'}}>
                            <picture>
                                <source media='(max-width: 767px)' srcSet='/assets/images/banner/banner-1.webp' />
                                <source media='(min-width: 768px)' srcSet='/assets/images/banner/banner-1.webp'/>
                                <img src='/assets/images/banner/banner-1.webp' alt='banner' className='img-fluid' style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                            </picture>
                        </div>
                    </div>
                    <div className='item' >
                        <div className='item-slide' style={{width: '100%', height: '100%'}}>
                            <picture>
                                <source media='(max-width: 767px)' srcSet='/assets/images/banner/banner-2.webp' />
                                <source media='(min-width: 768px)' srcSet='/assets/images/banner/banner-2.webp'/>
                                <img src='/assets/images/banner/banner-2.webp' alt='banner' className='img-fluid' style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                            </picture>
                        </div>
                    </div>
                    <div className='item' >
                        <div className='item-slide' style={{width: '100%', height: '100%'}}>
                            <picture>
                                <source media='(max-width: 767px)' srcSet='/assets/images/banner/banner-3.webp' />
                                <source media='(min-width: 768px)' srcSet='/assets/images/banner/banner-3.webp'/>
                                <img src='/assets/images/banner/banner-3.webp' alt='banner' className='img-fluid' style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                            </picture>
                        </div>
                    </div>
                </OwlCarousel>
            </section>
        </Fragment>
    )
}

export default Hero;