import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Footer(props) {
    return (
        <Fragment>
            <footer>
                <div className='footer-first'>
                    <Container>
                        <Row className='justify-content-between'>
                            <Col xl="4" lg="4" md="4" className='pb-5 pb-xl-0 pb-lg-0 pb-md-0'>
                                <div className='pb-4 text-xl-left text-lg-left text-md-left text-center'>
                                    <a href='/'><img src='/assets/images/logo/AIM.png' alt='logo aim' className='img-fluid' width='245' height='80' /></a>
                                </div>
                                <ul className='list-unstyled alamt'>
                                    <li>
                                        <i className="fab fa-instagram"></i>
                                        <span>Example</span>
                                    </li>
                                    <li>
                                        <i className="fab fa-facebook-f"></i>
                                        <span>Example</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col xl="4" lg="4" md="3" className='pb-5 pb-xl-0 pb-lg-0 pb-md-0'>
                                <ul className='list-unstyled menu'>
                                    <li>
                                        <a href="/" title='Home'>Home</a>
                                    </li>
                                    <li>
                                        <a href="/brands" title='Brands'>Brands</a>
                                    </li>
                                    <li>
                                        <a href="/about-us" title='About Us'>About Us</a>
                                    </li>
                                    <li>
                                        <a href="/career" title='Careers'>Careers</a>
                                    </li>
                                    <li>
                                        <a href="/contact-us" title='Contact Us'>Contact Us</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col xl="4" lg="4" md="4" className='pb-5 pb-xl-0 pb-lg-0 pb-md-0'>
                                <div className='pb-3'>
                                    <p className="title">Address</p>
                                    <span className="desc">Ruko Alam Sutera Victoria Lane No. 38,<br/>Kota Tangerang<br/>Banten, 15143</span>
                                </div>
                                <ul className='list-unstyled alamt'>
                                    <li>
                                        <i className="fas fa-phone"></i>
                                        <span>0800 - 0000 - 0000</span>
                                    </li>
                                    <li>
                                        <i className="fas fa-envelope"></i>
                                        <span>example@example.com</span>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='footer-second text-center'>
                    <p>Artha Inti Mentari © Copyright 2021 - Powered by <a href="https://imajinative.agency/" target="_blank" rel="noreferrer">imajinative</a></p>
                </div>
            </footer>
        </Fragment>
    );
}

export default Footer;