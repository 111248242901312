import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Career() {
    return (
        <Fragment>
            <section className='career' id='career' style={{backgroundImage: `url(https://preview.colorlib.com/theme/constructed/images/ximg_4.jpg.pagespeed.ic.YIZo8jz045.webp)`}}>
                <div className='overlay' id='lay'></div>
                <Container>
                    <div className='section-head d-flex align-items-center justify-content-center'><h2>Careers</h2></div>
                    <div className='section-body pt-3 pt-xl-5 pt-lg-5 md-pt-4'>
                        <Row className='align-items-center'>
                            <Col xl='4' lg='4' md='4'>
                                <div className='section-head'><p className='title'>Join Our Team!</p></div>
                                <div className='section-body py-2 py-xl-3 py-lg-3 py-md-3'><p>We are always open to accept new members who have the same vision and mission as us.</p></div>
                                <a href='/career' className='btn btn-more_1'>Discover More</a>
                            </Col>
                            <Col xl='8' lg='8' md='8' className='pt-3 pt-xl-0 pt-lg-0 pt-md-3'>
                                <img src='/assets/images/career.jpeg' className='img-fluid' alt=''/>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Fragment>
    );
}

export default Career;