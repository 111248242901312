import React, { Fragment } from 'react'
import Helmet from 'react-helmet'

// content
import Content from '../content/contact/Content'

function ContactUs() {
    return (
        <Fragment>
            <Helmet>
                <link rel="canonical" href={process.env.REACT_APP_STAGING + 'contact-us'} />
                <link rel='shortlink' href={process.env.REACT_APP_STAGING + 'contact-us'} />
                {/* Primary Meta Tags */}
                <title>Artha Inti Mentari</title>
                <meta name="title" content="Contact Us"/>
                <meta name="description" content="Arta Inti Mentari"/>
                <meta name="keywords" content="Arta Inti Mentari"/>

                <meta itemprop="title" content="Contact Us"/>
                <meta itemprop="description" content="Arta Inti Mentari"/>
                <meta itemprop="image" content="/fav-icon-gst.png"/>

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={process.env.REACT_APP_STAGING + 'contact-us'}/>
                <meta property="og:title" content="Contact Us"/>
                <meta property="og:description" content="Arta Inti Mentari"/>
                <meta property="og:image" content="/fav-icon-gst.png"/>

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={process.env.REACT_APP_STAGING + 'contact-us'}/>
                <meta property="twitter:title" content="Contact Us"/>
                <meta property="twitter:description" content="Arta Inti Mentari"/>
                <meta property="twitter:image" content="/fav-icon-gst.png"/>
                
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow"/>
                <meta name="googlebot-news" content="index, follow"/>
            </Helmet>
            <h1 className="firstHeading MSI_show_element">Contact Us - Artha Inti Mentari</h1>
            <Content/>
        </Fragment>
    );
}

export default ContactUs;