import React, { Fragment } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Iframe from 'react-iframe'

function Content() {
    function sendEmail(e) {
        e.preventDefault();
    }
    return (
        <Fragment>
            <section className='contact-content h-100'>
                <Container>
                    <Row>
                        <Col xl={6} lg={6} md={6}>
                            <Iframe className="maps-kantor" tabIndex="0" allowFullScreen={true} aria-hidden="false" style={{border: "0"}} frameBorder="0" width="100%" height="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.5678817301972!2d106.64932922352217!3d-6.227887786446913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x52fd0f89f6835da1!2zNsKwMTMnNDAuMSJTIDEwNsKwMzgnNTkuMCJF!5e0!3m2!1sid!2sid!4v1648129733747!5m2!1sid!2sid"/>
                        </Col>
                        <Col xl={6} lg={6} md={6}>
                            <div className='section-body'>
                                <p className='head-title pb-3'>Head Office</p>
                                <ul className='list-unstyled contact-person'>
                                    <li>
                                        <a href='tel:081234567890' target='_blank' title='Phone' rel='noreferrer'>
                                            <i className="fas fa-phone"></i>
                                            <span>0800 - 0000 - 0000</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='tel:081234567890' target='_blank' title='Phone' rel='noreferrer'>
                                            <i className="fas fa-envelope"></i>
                                            <span>example@example.com</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='tel:081234567890' target='_blank' title='Phone' rel='noreferrer'>
                                            <i className="fab fa-instagram"></i>
                                            <span>Example</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='tel:081234567890' target='_blank' title='Phone' rel='noreferrer'>
                                            <i className="fab fa-facebook-f"></i>
                                            <span>Example</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} className='mt-5'>
                            <div className='section-heading'>
                                <p className='title-form pb-3'>Send us a message</p>
                                <p className=' pb-3'>Please complete the form below, we will contact you as soon as possible.</p>
                            </div>
                            <Form className="form-contact contact_form" id="contactForm" validated={false} onSubmit={sendEmail}>
                                <Row>
                                    <Col xl={6} lg={6} md={6}>
                                        <Form.Group>
                                            <Form.Control type="text" name="first_name" placeholder='First Name' />
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} lg={6} md={6}>
                                        <Form.Group>
                                            <Form.Control type="text" name="Last_name" placeholder='Last Name' />
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} lg={6} md={6}>
                                        <Form.Group>
                                            <Form.Control type="text" name="phone" placeholder='Phone' />
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} lg={6} md={6}>
                                        <Form.Group>
                                            <Form.Control type="email" name="email" placeholder='Email' />
                                        </Form.Group>
                                    </Col>
                                    <Col xl={12} lg={12} md={12}>
                                        <Form.Group>
                                            <Form.Control as="textarea" name="message" rows={4} placeholder='Message' />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className='mt-5'>
                                    <Button type="submit" className="btn btn-consult" variant='default'>Submit</Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default Content;