import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel'
import axios from 'axios'

function Brand() {
    const options = {
        margin: 20,
        center: true,
        loop: true,
        dots: false,
        nav: true,
        autoplay: false,
        smartSpeed: 1250,
        items: 3,
        navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    }
    const [brands, listbrands] = useState([]);
    useEffect(() => {
        const url = process.env.REACT_APP_API + `brands`
        axios.get(url)
        .then(res => {
            listbrands(res.data.data)
        })
        .catch(error => console.log(error))
    },[]);
    return (
        <Fragment>
            <section className='brands'>
                <Container>
                    <Row className='align-items-center justify-content-between'>
                        <Col xl="3" lg="3">
                            <div className='section-head text-white'><h2>Our Brands</h2></div>
                            <div className='section-body'>
                                <p className='description py-3 py-xl-5 py-lg-5 py-md-4'>AIM provide High Quality daily use product for Beauty, Personal Care & Baby Product, etc. Our product always makes with newest technology & ingredient with tight QC to make sure suitable for our customer.</p>
                                <a href='/brands' className='btn btn-more'>Discover More</a>
                            </div>
                        </Col>
                        <Col xl="8" lg="8" className='pt-3 pt-xl-0 pt-lg-0 pt-md-3'>
                            {
                                brands.length && (
                                    <OwlCarousel className="owl-theme" {...options}>
                                        {
                                            brands.map((data, i) => {
                                                return(
                                                    <div className='item' key={i}>
                                                        <div className='box'>
                                                            <div className='box-logo'>
                                                                <img src={data.logo} className='img-fluid' alt={data.title} width='150' height='150' />
                                                            </div>
                                                            <div className='box-desc'>
                                                                <a href={data.link} className='btn btn-lg btn-visit'>Discover More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </OwlCarousel>
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default Brand;